// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
import type { ProductListItem } from "~/models/products";

export interface TransactionInfo {
  /** The transaction ID. */
  transactionId: string;
  /** The monetary value of the event. Don't include shipping or tax. */
  totalGross: number;
  /** The currency code. If not set, the default currency code will be used. */
  currency?: string;
  /** The optional shipping cost. */
  shippingCost?: number;
  /** The optional tax amount. */
  tax?: number;

  products: ProductListItem[];
};

// https://support.google.com/analytics/answer/9267735?hl=en
type EventNames = "view_item"
  | "add_to_cart" | "remove_from_cart" | "add_to_wishlist"
  | "view_item_list" | "select_item"
  | "view_cart" | "begin_checkout" | "add_payment_info" | "add_shipping_info" | "purchase";
//  | "view_promotion" | "select_promotion";

class Ga4Product {
  /**
   * Item ID (context-specific).
   * One of item_id or item_name is required for product or impression data.
   */
  public item_id!: string;

  /**
   * Item ID (context-specific).
   * One of item_id or item_name is required for product or impression data.
   */
  public item_name!: string;

  /**
   * Item quantity.
   */
  public quantity?: number;

  /**
   * A product affiliation to designate a supplying company or brick and mortar store location.
   */
  public affiliation?: string;

  /**
   * Coupon code used for a purchase.
   */
  public coupon?: string;

  /**
   * Monetary value of discount associated with a purchase.
   */
  public discount?: number;

  /**
   * Item brand
   */
  public item_brand?: string;

  /**
   * Item Category (context-specific).
   */
  public item_category?: string;

  /**
   * Item Category (context-specific). item_category2 can also be used if the item has many categories.
   */
  public item_category2?: string;

  /**
   * Item Category (context-specific). item_category3 can also be used if the item has many categories.
   */
  public item_category3?: string;

  /**
   * Item Category (context-specific). item_category4 can also be used if the item has many categories.
   */
  public item_category4?: string;

  /**
   * Item Category (context-specific). item_category5 can also be used if the item has many categories.
   */
  public item_category5?: string;

  /**
   * The item variant or unique code or description for additional item details/options.
   */
  public item_variant?: string | null;

  /**
   * The ID of the list in which the item was presented to the user.
   */
  public item_list_id?: string | null;
  /**
   * The name of the list in which the item was presented to the user.
   */
  public item_list_name?: string | null;

  /**
   * The ID of the promotion associated with the item.
   */
  public promotion_id?: string | null;
  /**
   * The name of the promotion associated with the item.
   */
  public promotion_name?: string | null;

  /**
   * The monetary price of the item per sales unit, in the specified currency parameter.
   */
  public price?: number;

  /**
   * The index of the item in a list.
   */
  public index?: number | null;
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
export function useGtmTracking() {
  const { dataLayer } = useScriptGoogleTagManager();

  function getCurrencyCode(): string {
    let currencyCode = document.body.getAttribute("data-datalayer-currency");
    if (currencyCode == null || currencyCode.length < 1) {
      currencyCode = "EUR";
    }

    return currencyCode;
  }

  // TODO refactor from ProductListItem to specific class with the analytics properties?
  // TODO that would make the composable more reusable
  function convertToGA4(
    product: ProductListItem,
  ): Ga4Product {
    const item = {
      item_id: product.analytics.id,
      item_name: product.analytics.name,
      item_brand: product.analytics.brand,
      quantity: product.analytics.quantity || 1,
      price: product.analytics.price,
      index: product.analytics.index,
      // affiliation: null,
      // coupon: null,
    } satisfies Ga4Product as Ga4Product;

    // TODO: TBD do we need to set the price here? probably not
    // if (price && price.unitPrice) {
    //   item.price = price.unitPrice;
    //   item.discount = price.originalUnitPrice ? price.originalUnitPrice - price.unitPrice : undefined;
    // }

    if (product.analytics.category) {
      const categories = (product.analytics.category || "").split(";");
      if (categories.length > 0) {
        item.item_category = categories.at(0);
      }
      if (categories.length > 1) {
        item.item_category2 = categories.at(1);
      }
      if (categories.length > 2) {
        item.item_category3 = categories.at(2);
      }
      if (categories.length > 3) {
        item.item_category4 = categories.at(3);
      }
      if (categories.length > 4) {
        item.item_category5 = categories.at(4);
      }
    }

    return item;
  }

  function trackEcommerceEvent(
    eventName: EventNames,
    ecommerceData: { items: Ga4Product[] } & Record<string, any>,
  ) {
    if (!import.meta.client) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log("trackEvent", eventName, ecommerceData);

    if (dataLayer) {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: eventName,
        ecommerce: {
          ...ecommerceData,
          currency: ecommerceData.value ? (ecommerceData.currency ?? getCurrencyCode()) : null,
        },
      });
    }
  }

  /**
   * Track a custom event in Google Tag Manager.
   * The "custom_" prefix will be added automatically.
   * @param eventName
   * The name of the custom event.
   * Naming in the form of `noun-verb`, where verb is current tense, i.e. `favorite-list-create`.
   * Do not include the "custom_" prefix!
   */
  function trackCustomEvent(eventName: string) {
    if (!import.meta.client) {
      return;
    }

    const completeEventName = eventName.startsWith("custom_") ? eventName : `custom_${eventName}`;

    // eslint-disable-next-line no-console
    console.log("trackEvent", completeEventName);

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: completeEventName,
      ecommerce: null,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#search
  function trackSearch(query: string) {
    if (!import.meta.client) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log("trackSearch", query);

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "search",
      search_term: query,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_details
  function trackProductDetail(product: ProductListItem | null) {
    if (!product) {
      return;
    }
    const item = convertToGA4(product);
    setTimeout(() => {
      trackEcommerceEvent("view_item", {
        items: [item],
      });
    }, 1000);
  }

  function trackAddToFavorites(products: ProductListItem[]) {
    const items = products.map(convertToGA4);

    trackEcommerceEvent("add_to_wishlist", {
      items: items,
    });
  }

  function trackAddToCart(
    product: ProductListItem,
    data: {
      quantity: number;
      /** Don't include shipping or tax. */
      unitPriceGross: number;
      listName?: string;
      /** Position in the list. */
      index?: number;
    },
  ) {
    const item = convertToGA4(product);
    item.quantity = data.quantity;
    item.price = data.unitPriceGross;
    setList(item, data.listName, data.index);

    trackEcommerceEvent("add_to_cart", {
      items: [item],
      value: data.quantity * data.unitPriceGross,
    });
  }

  function trackRemoveFromCart(products: ProductListItem[]) {
    const items = products.map(convertToGA4);

    trackEcommerceEvent("remove_from_cart", {
      items: items,
    });
  }

  function trackProductList(products: ProductListItem[], listName: string) {
    const items = products.map(convertToGA4);
    for (const item of items) {
      setList(item, listName, items.indexOf(item));
    }

    trackEcommerceEvent("view_item_list", {
      items: items,
    });
  }

  function trackProductListItemClick(product: ProductListItem, listName: string, index: number) {
    const item = convertToGA4(product);
    setList(item, listName, index);

    trackEcommerceEvent("select_item", {
      items: [item],
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
  /**
   * Track that a user has begun a checkout.
   * @param products The products in the cart.
   * @param cartTotalGross The monetary value of the event. Don't include shipping or tax.
   */
  function trackViewCart(products: ProductListItem[], cartTotalGross: number) {
    const items = products.map(convertToGA4);

    trackEcommerceEvent("view_cart", {
      items: items,
      value: cartTotalGross,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
  /**
   * Track that a user has begun a checkout.
   * @param products The products in the cart.
   * @param cartTotalGross The monetary value of the event. Don't include shipping or tax.
   */
  function trackBeginCheckout(products: ProductListItem[], cartTotalGross: number) {
    const items = products.map(convertToGA4);

    trackEcommerceEvent("begin_checkout", {
      items: items,
      value: cartTotalGross,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_shipping_info
  /**
   * Track the shipping method used during checkout.
   * @param products The products in the cart.
   * @param cartTotalGross The monetary value of the event. Don't include shipping or tax.
   * @param shippingMethod The selected shipping method.
   */
  function trackCheckoutShippingInfo(products: ProductListItem[], cartTotalGross: number, shippingMethod: string) {
    const items = products.map(convertToGA4);

    trackEcommerceEvent("add_shipping_info", {
      items: items,
      value: cartTotalGross,
      shipping_tier: shippingMethod,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_payment_info
  /**
   * Track the payment method used during checkout.
   * @param products The products in the cart.
   * @param cartTotalGross The monetary value of the event. Don't include shipping or tax.
   * @param paymentType The selected payment method.
   */
  function trackCheckoutPaymentInfo(products: ProductListItem[], cartTotalGross: number, paymentType: string) {
    const items = products.map(convertToGA4);

    trackEcommerceEvent("add_payment_info", {
      items: items,
      value: cartTotalGross,
      payment_type: paymentType,
    });
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
  /**
   * Track when one or more items is purchased by a user.
   * @param transaction The transaction data.
   */
  function trackPurchase(transaction: TransactionInfo) {
    const items = transaction.products.map(convertToGA4);

    trackEcommerceEvent("purchase", {
      items: items,
      transaction_id: transaction.transactionId,
      currency: transaction.currency,
      value: transaction.totalGross,
      shipping: transaction.shippingCost ? transaction.shippingCost : null,
      tax: transaction.tax ? transaction.tax : null,
    });
  }

  return {
    trackCustomEvent: trackCustomEvent,
    trackSearch: trackSearch,
    trackProductDetail: trackProductDetail,
    trackProductList: trackProductList,
    trackProductListItemClick: trackProductListItemClick,
    trackAddToFavorites: trackAddToFavorites,
    trackAddToCart: trackAddToCart,
    trackRemoveFromCart: trackRemoveFromCart,
    trackViewCart: trackViewCart,
    trackBeginCheckout: trackBeginCheckout,
    trackCheckoutShippingInfo: trackCheckoutShippingInfo,
    trackCheckoutPaymentInfo: trackCheckoutPaymentInfo,
    trackPurchase: trackPurchase,
  };
}
function setList(item: Ga4Product, listName?: string, index?: number) {
  item.index = index;
  if (listName) {
    // convert list name to kebab-case
    item.item_list_name = listName;
    item.item_list_id = listName.toLowerCase().replace(/\s+/g, "-");
  }
}
